import React, { useContext, useEffect, useState } from 'react';
import store from 'store';
import { Heading, Box, Text, Flex } from 'rebass';
import { useRouter } from 'next/router';
import { useWindowSize } from 'react-use';
import Layout from '@/components/Layout';
import Grid from '@/components/app/Grid';
import { useIsCurrentUserAuthenticated, useIsCurrentUserHasUsernamePassword } from '@/hooks/auth';
import { useCurrentMember } from '@/hooks/member';
import { AuthContext, useFetchAuthToken } from '@/components/Auth/index';
import { useClearEntityCache } from '@/lib/entities';
import { ax } from '@/lib/api';
import { useMobileCheckHook } from '@/hooks/celebration';
import WelcomePage from '@/components/welcome';
import { clearCelebrationId, sendTrackingEvent } from '@/lib/helper';
import { CelebrationList } from '@/components/CelebrationList/CelebrationListContext';
import CelebrationTemplateBox from '@/components/CelebrationTemplateBox';
import useGetCelebrationTypes from '@/hooks/celebrationtypes';

function DashboardPage() {
  const {
    pathname,
    push,
    query: { restore_token: restoreToken, secret },
  } = useRouter();
  const isAuthenticated = useIsCurrentUserAuthenticated();
  const isUserHasPassword = useIsCurrentUserHasUsernamePassword();
  const currMember = useCurrentMember();
  const [member, setMember] = useState(currMember || undefined);

  const [, dispatch] = useContext(AuthContext);
  const clearCache = useClearEntityCache();
  const isFromMobile = useMobileCheckHook();
  const { width } = useWindowSize();
  const fetchAuthToken = useFetchAuthToken();
  const listOfCelebrationTypes = useGetCelebrationTypes();

  const [refreshInterval, setRefreshInterval] = useState(null);
  const [showProcessing, setShowProcessing] = useState(true);
  const [error, setError] = useState(null);

  async function GetData() {
    const { data: mainData } = await ax().get(`/v3/me`);
    setMember(mainData?.data?.attributes);
  }

  useEffect(() => {
    GetData();
    clearCelebrationId();
  }, []);

  useEffect(() => {
    if (store.get('buzzCodeError')) {
      setError(store.get('buzzCodeError'));
      store.remove('buzzCodeError');
      setTimeout(() => setError(null), 5000);
    }
  }, [store.get('buzzCodeError')]);

  const shouldRedirectToSignIn = !isUserHasPassword && pathname === '/' && !isAuthenticated;
  // useEffect(() => {
  //   clearCache();
  // }, []);

  useEffect(() => {
    if (member) {
      if (!member.onboarded) {
        setRefreshInterval(5000);
        setTimeout(() => {
          setShowProcessing(false);
        }, 5000);
      }
    }
    if (member?.onboarded) {
      setRefreshInterval(null);
    }
  }, [member, showProcessing, refreshInterval]);

  function logUserOut() {
    dispatch({ type: 'logout' });
    store.remove('token');
    store.remove('selectedDeviceID');
    store.remove('selectedAudioDeviceID');
    // store.remove('isProducer');
    clearCache();
  }

  useEffect(() => {
    if (
      member === null ||
      // mainData?.data?.data?.attributes?.['has-username-password-identifier'] === false ||
      member?.['has-username-password-identifier'] === false
    ) {
      dispatch({ type: 'logout' });
      // store.remove('token');
      // store.remove('isProducer');
      clearCache();
      push(`/sign-in${window.location.search}`);
    }
  }, [member]);

  function handleSignOutAndRedirect() {
    logUserOut();
    push(`/sign-in${window.location.search}`);
  }

  async function getTokenForUser() {
    const responseOfToken = await fetchAuthToken({ id: restoreToken, secret });
    const { token } = responseOfToken;
    if (token) {
      window.location.href = window.location.origin;
    } else {
      handleSignOutAndRedirect();
    }
  }

  useEffect(() => {
    if (restoreToken && secret) {
      getTokenForUser();
    }
  }, [restoreToken]);

  useEffect(() => {
    const shouldRedirect =
      shouldRedirectToSignIn &&
      !store.get('token') &&
      !window.location.href.includes('restore_token');
    if (shouldRedirect) {
      handleSignOutAndRedirect();
    }
  }, [shouldRedirectToSignIn]);

  if (member?.onboarded === undefined) return null;

  if (!listOfCelebrationTypes)
    return (
      <Layout authenticated isFromHome>
        <Flex height="70vh" justifyContent="center" alignItems="center">
          <Heading variant="headings.h1" className="textLoading">
            Loading
          </Heading>
        </Flex>
      </Layout>
    );

  if (listOfCelebrationTypes.length === 0)
    return (
      <Layout authenticated isFromHome>
        <WelcomePage
          onSetGetStarted={() => {
            sendTrackingEvent(`member_visit`, {
              context: {
                url_text: 'Create your First Celebration',
                url: '/event',
              },
            });
            push('/event');
          }}
          name={member['first-name']}
        />
      </Layout>
    );

  let gridGap = 4;
  if (isFromMobile) {
    if (width < 500) gridGap = 1;
    else gridGap = 2;
  }

  return (
    <Layout authenticated isFromHome maxWidth="100vw" showFooter={false}>
      {error && (
        <Box bg="pinks.1" p={2} mb={2}>
          <Heading variant="headings.h5" color="pinks.4">
            {error}
          </Heading>
        </Box>
      )}
      {(member?.onboarded === false && showProcessing) || !listOfCelebrationTypes ? (
        <Flex height="70vh" justifyContent="center" alignItems="center">
          <Heading variant="headings.h1" className="textLoading">
            Processing
          </Heading>
        </Flex>
      ) : (
        <>
          {member?.['first-name'] && (
            <Heading variant="headings.h1" pb={3} color="black" textAlign="center">
              💫 Happy {new Date().toLocaleDateString('en-US', { weekday: 'long' })}
              {', '}
              {member['first-name']}!
            </Heading>
          )}
          <Text textAlign="center" color="#8E8E8E">
            {listOfCelebrationTypes.length === 0
              ? 'Excited to create your first celebration? Choose from one of three video styles!'
              : 'View and Manage Your Celebrations'}
          </Text>
          <Grid
            py={isFromMobile ? 2 : 5}
            gridGap={gridGap}
            justifyContent="center"
            gridTemplateColumns={
              isFromMobile
                ? 'repeat(auto-fill, minmax(100vw, 100vw))'
                : 'repeat(auto-fill, minmax(390px, 25vw))'
            }
          >
            {listOfCelebrationTypes.map((attributes) => (
              <CelebrationList>
                <CelebrationTemplateBox
                  isFromMobile={isFromMobile}
                  id={`${attributes?.templateId}-list-item`}
                  {...attributes}
                  clearCache={clearCache}
                />
              </CelebrationList>
            ))}
          </Grid>
        </>
      )}
    </Layout>
  );
}

export default DashboardPage;
