import React from 'react';
import { Text, Button, Flex, Image } from 'rebass';
import image from '@/components/app/welcome.svg';

function WelcomePage({ onSetGetStarted, name }) {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      maxWidth={656}
      m="auto"
      my={4}
      sx={{
        '@media (max-width: 656px)': {
          p: 2,
        },
      }}
    >
      <Image src={image} />
      <Text
        my={4}
        className="font-zilla"
        sx={{
          fontWeight: 500,
          fontSize: '50px',
          lineHeight: '42px',
          textAlign: 'center',
          color: '#272929',
        }}
      >
        👋 Welcome to Celebrate, {name}!
      </Text>
      <Button
        className="dm-sans"
        px={2}
        onClick={onSetGetStarted}
        width="50%"
        mb={2}
        type="button"
        variant="special"
        sx={{ textTransform: 'none' }}
      >
        Create your First Celebration
      </Button>
    </Flex>
  );
}

export default WelcomePage;
